<template>
  <h1>Leerlingen</h1><br>

  <div class="nav justify-content-end mb-md-3" v-if="this.$store.state.user.manageStudents">
    <button class="btn btn-primary m-2" type="button" @click="addNewGroup"><fa icon="fa-solid fa-users" /> Groep toevoegen</button>
  </div>
  <div v-if="!loadingGroups && studentGroups.length == 0" class="smoothContainer text-center">
   Er zijn nog geen groepen aangemaakt.<br>
   Gebruik de 'Groep toevoegen' knop om een eerste groep/klas aan te maken.  
  </div>

  <div
    class="smoothContainer"
    id="groupsAccordion"
    v-for="(group, groupIndex) in studentGroups"
    :key="groupIndex"
    :class="{ 'drag-over': group.isDragOver }"
    @dragover.prevent="onDragOver(group)"
    @dragleave="onDragLeave(group)"
    @drop="onDrop($event, group)"
  >
      <h5 :class="{'mb-0':!group.opened}" @click="toggleStudentsPane($event,group)" role="button" :id="'header' + groupIndex" class="user-select-none w-100">
        {{ group.name }}
        <span class="float-end">
          <fa icon="fa fa-angle-up" v-if="group.opened" />
          <fa icon="fa fa-angle-down" v-else />
        </span>
      </h5>

      <div class="animateIn" :id="'collapse' + groupIndex" v-if="group.opened" >
        <div>
          <LoadingSpinner :loading="group.loading" />
          <div class="nav justify-content-end mb-md-2 animateIn" v-if="this.$store.state.user.manageStudents">
            <button class="btn btn-primary btn-sm m-2" type="button" @click="renameGroup(group)"><fa icon="fa-solid fa-pen-to-square" /> Hernoemen</button>
            <button class="btn btn-primary btn-sm m-2" type="button" @click="addNewStudent"><fa icon="fa-solid fa-user" /> Leerling toevoegen</button>
            <div class="red-tooltip" style="display: inline-block;" 
               ref="csvInfo" data-bs-toggle="tooltip" 
               data-bs-placement="top" 
               title="Gebruik deze knop om een CSV-bestand te importeren met leerlinggegevens. Het bestand moet kolommen bevatten zoals 'Voornaam', 'Achternaam', 'Geboortedag', en 'Aanwezig'.">
              <button class="btn btn-secondary btn-sm m-2" 
                  type="button" 
                  @click="importStudents(group)" 
                  :disabled="importingStudents">
              <fa icon="fa-solid fa-file-import" />
              {{ importingStudents ? `Bezig met importeren van ${importingCount} leerlingen` : 'Importeren CSV' }}
              </button>
            </div>
            <button class="btn btn-danger btn-sm m-2" type="button" @click="removeGroup(group)"><fa icon="fa-solid fa-trash" /> Verwijder groep</button>
          </div>
          
          <table class="table table-hover" style="table-layout: auto !important;">
            <thead v-if="this.$store.state.user.manageStudents" class="text-nowrap">
              <tr>
                <th scope="col">Naam</th>
                <th scope="col">Level</th>
                <th scope="col">Totaal spellen gehaald</th>
                <th scope="col"> </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!group.loading && (!group.students || group.students.length === 0)">
                <td colspan="4" class="text-center">Groep is leeg</td>
              </tr>
              <tr
                v-for="(student, studentIndex) in group.students"
                :key="studentIndex"
                style="cursor:pointer !important;"
                draggable="true"
                @dragstart="onDragStart($event, student, group)"
                @dragover.prevent
                @drop="onDrop($event, group)"
              >
                <td @click="openStudent(student)">{{ student.name }}</td>
                <td v-if="this.$store.state.user.manageStudents" @click="openStudent(student)">{{ student.level }}</td>
                <td v-if="this.$store.state.user.manageStudents" @click="openStudent(student)">{{ student.currencyReceived }}</td>
                <td v-if="this.$store.state.user.manageStudents">
                  <a href="#" class="text-danger ms-2 tinyRemove" @click.prevent="deleteStudent(student, group)">
                    <fa icon="fa-solid fa-circle-minus" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
</div>

<HelpComponent message='<p>Met ons systeem kun je gemakkelijk groepen aanmaken en leerlingen toevoegen aan deze groepen.<br>
        Zo kun je bijvoorbeeld een groep maken voor alle leerlingen in een bepaalde klas, of een groep voor leerlingen die extra hulp nodig hebben.<br>
        Om een nieuwe groep aan te maken, klik je op de knop "Groep toevoegen" in het menu aan de rechterkant van het scherm.</p>
        <p>Om leerlingen aan een groep toe te voegen, klik je eerst op de groep waaraan je leerlingen wilt toevoegen. Vervolgens klik je op de knop "Leerling toevoegen" en voer je de naam van de leerling in.</p>
        <p>Als je de details van een leerling wilt bekijken, klik je eerst op de groep waarbinnen de leerling zit. Vervolgens klik je op de naam van de leerling in de lijst met leerlingen in de groep. Dit zal een nieuw scherm openen met alle details van de leerling, zoals hun naam, leeftijd en voortgang.</p>'/>

</template>

<script>
import { db } from '@/main'
import { firestore } from '@/main'
import { router } from '@/router';
import HelpComponent from './Nested/HelpComponent.vue'
import LoadingSpinner from './Nested/LoadingSpinner.vue';
import Papa from 'papaparse';

export default {
  name: "StudentGroups",
  components: {
    HelpComponent,
    LoadingSpinner
},
  data() {
    return {
      loadingGroups: true,
      lastLoadedGroup: null,
      ungroupedStudents: [],
      modifiedStudentData: {},
      activeStudent: null,
      help: false,
      studentGroups: [],
      importingStudents: false, // Track if import is in progress
      importingCount: 0, // Track the number of students being imported
    };
  },
  methods: {
    toggleStudentsPane(event,targetGroup){
      console.log(targetGroup.opened);
      if(targetGroup.opened == true){
        targetGroup.opened = false;
      }
      else{
        this.loadStudents(targetGroup);
      }

      if (!event.shiftKey) {
        //Close all other groups
        this.studentGroups.forEach((group) => {
          if(group.id != targetGroup.id){
            group.opened = false;
          }
        });
      }
    },
    loadStudents(targetGroup)
    {
      targetGroup.opened = true;
      
      this.$store.state.groupId = targetGroup.id;  
      this.lastLoadedGroup = targetGroup;
      console.log("Load students for group " + targetGroup.id);
      
      db.collection("/schools/" + this.$store.state.schoolId + "/students").where('groupId','==',targetGroup.id).get().then(this.assignStudentsToGroup);  
    },
    loadUngroupedStudents()
    {
      //Load students and assign to ungroupedStudents if they have no groupId, or the group with the id does not exist
      db.collection("/schools/" + this.$store.state.schoolId + "/students").get().then((studentDocs) => {
        let _ungroupedStudents = [];
        studentDocs.forEach((item) => {
          let student = item.data();
          student.id = item.id;
          if(student.groupId == null || student.groupId == ""){
            _ungroupedStudents.push(student);
          }
        });
        this.ungroupedStudents = _ungroupedStudents;
      });
    },
    assignStudentsToGroup(studentDocs){
      let _students = [];
      studentDocs.forEach((item) => {
        let student = item.data();
        student.id = item.id;

        //Load last 3 results for student
        db.collection("/activities").doc(student.activityId).collection("results").orderBy("logged", "desc").limit(3).get().then((activityDocs) => {
          activityDocs.forEach((activityDoc) => {
            let activity = activityDoc.data();
            student.activity = activity;
          });
        });

        _students.push(student);
      });

      //Sort by name
      _students.sort((a, b) => a.name.localeCompare(b.name));
      this.lastLoadedGroup.students = _students;
      this.lastLoadedGroup.loading = false;
    },
    addNewGroup()
    {
      let groupName = prompt("Wat is de groep naam?");
      let timestamp = firestore.FieldValue.serverTimestamp()
      if(groupName != null){
        let newGroup = {
          name: groupName,
          number: 0,
          created: timestamp
        }
        let newDoc = db.collection("/schools/" + this.$store.state.schoolId + "/groups").doc();
        newDoc.set(newGroup);
        console.log("Added new group with auto ID" + newDoc.id); 
      } 
    },
    renameGroup(group){
      let groupName = prompt("Groep '" + group.name + "' hernoemen:");
      if(groupName != null){
        db.collection("/schools/" + this.$store.state.schoolId + "/groups").doc(group.id).update({
          name:groupName
        }).then(() => {
            console.log("Group successfully renamed!");
        }).catch((error) => {
            console.error("Error renaming group document: ", error);
        });
      } 
    },
    removeGroup(group)
    {
      if(confirm("Remove group " + group.name + "?"))
      {
        console.log("Removing group");  
        db.collection("/schools/" + this.$store.state.schoolId + "/groups").doc(group.id).delete().then(() => {
            console.log("Group successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
       }
    },
    addNewStudent()
    {
      let groupId = "";
      if(this.lastLoadedGroup != null)
      {
        groupId = this.lastLoadedGroup.id;
      }
      let timestamp = firestore.FieldValue.serverTimestamp();
      let newStudent = {
          name: "Nieuwe student",
          number: 0,
          groupId: groupId,
          created: timestamp
      }
      let newDoc = db.collection("/schools/" + this.$store.state.schoolId + "/students").doc();
      newDoc.set(newStudent);
      console.log("Added new student with auto ID" + newDoc.id); 

      this.openStudent(newDoc);
    },
    openStudent(student)
    {
      router.push('/student/' + student.id);
    },
    processStudentData(rows, groupId) {
      const dayMapping = {
        'maandag': 0,
        'dinsdag': 1,
        'woensdag': 2,
        'donderdag': 3,
        'vrijdag': 4,
        'zaterdag': 5,
        'zondag': 6,
      };

      const parseDate = (dateString) => {
        // Try parsing as Dutch notation (day-month-year)
        const dutchMatch = dateString.match(/^(\d{1,2})-(\d{1,2})-(\d{4})$/);
        if (dutchMatch) {
          const [_, day, month, year] = dutchMatch;
          return new Date(`${year}-${month}-${day}`);
        }

        // Fallback to ISO or other formats
        const parsedDate = new Date(dateString);
        return isNaN(parsedDate) ? null : parsedDate;
      };

      return rows.map((row) => {
        const columns = row.split('\t'); // Assuming tab-separated values
        const birthday = columns[2] ? parseDate(columns[2]) : null;
        const isValidDate = birthday instanceof Date && !isNaN(birthday);

        return {
          name: `${columns[0]} ${columns[1]}`, // Assuming first two columns are first and last name
          birthday: isValidDate ? firestore.Timestamp.fromDate(birthday) : null, // Validate date before converting
          days: columns[3]
            ? columns[3]
                .split(',')
                .map((day) => dayMapping[day.trim().toLowerCase()] ?? null)
                .filter((day) => day !== null)
            : [0, 1, 2, 3, 4, 5, 6], // Assuming fourth column is days
          groupId: groupId,
          created: firestore.FieldValue.serverTimestamp(),
        };
      });
    },
    importStudents(group) {
      this.lastLoadedGroup = group; // Set the group for clipboard imports
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '.csv';
      input.addEventListener('change', (event) => {
        const file = event.target.files[0];
        if (file) {
          Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
              // Detect delimiter
              const delimiter = results.meta.delimiter;
              console.log(`Detected delimiter: ${delimiter}`);

              const rows = results.data.map((row) => 
                `${row.Voornaam}\t${row.Achternaam}\t${row.Geboortedag}\t${row.Aanwezig}`
              );

              if (rows.length > 0) {
                const confirmImport = confirm(`Je staat op het punt om ${rows.length} leerlingen te importeren. Wil je doorgaan?`);
                if (!confirmImport) {
                  return;
                }
              }

              this.importStudentData(rows, group.id);
            },
            error: (error) => {
              console.error('Error parsing CSV:', error);
            },
            delimiter: '', // Auto-detect delimiter
          });
        }
        input.remove();
      });
      input.click();
    },
    handlePaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData('text');
      if (pastedData) {
        const rows = pastedData.split('\n').filter((row) => row.trim() !== '');
        if (rows.length > 0) {
          // Check if the first row contains headers
          const firstRow = rows[0].split('\t');
          const isHeader = firstRow.includes('Voornaam') && firstRow.includes('Achternaam') && firstRow.includes('Geboortedag');

          // Skip the header row if detected
          const dataRows = isHeader ? rows.slice(1) : rows;

          const confirmImport = confirm(`Je staat op het punt om ${dataRows.length} leerlingen te importeren. Wil je doorgaan?`);
          if (!confirmImport) {
            return;
          }

          this.importStudentData(dataRows, this.lastLoadedGroup ? this.lastLoadedGroup.id : '');
        }
      }
    },
    async importStudentData(rows, groupId) {
      const students = this.processStudentData(rows, groupId);

      this.importingStudents = true;
      this.importingCount = students.length;

      // Fetch existing students in the group to check for duplicates
      const existingStudentsSnapshot = await db.collection(`/schools/${this.$store.state.schoolId}/students`)
        .where('groupId', '==', groupId)
        .get();
      const existingStudents = existingStudentsSnapshot.docs.map(doc => doc.data().name);

      const promises = students.map(async (student) => {
        if (existingStudents.includes(student.name)) {
          const addDuplicate = confirm(`De student "${student.name}" bestaat al. Wil je deze toch toevoegen?`);
          if (!addDuplicate) {
            console.log(`Student "${student.name}" is overgeslagen.`);
            return Promise.resolve(); // Skip this student
          }
        }

        const newDoc = db.collection(`/schools/${this.$store.state.schoolId}/students`).doc();
        return newDoc.set(student).then(() => {
          console.log(`Student "${student.name}" toegevoegd.`);
        }).catch((error) => {
          console.error(`Fout bij het toevoegen van student "${student.name}":`, error);
        });
      });

      Promise.all(promises).then(() => {
        this.importingStudents = false;
        this.importingCount = 0;
        console.log("Import voltooid. Studenten worden opnieuw geladen...");
        this.loadStudents(this.lastLoadedGroup); // Reload students in the group
      }).catch((error) => {
        console.error('Fout bij het importeren van studenten:', error);
        this.importingStudents = false;
        this.importingCount = 0;
      });
    },
    deleteStudent(student, group) {
      if (confirm(`Weet je zeker dat je de leerling "${student.name}" wilt verwijderen?`)) {
        db.collection(`/schools/${this.$store.state.schoolId}/students`).doc(student.id).delete()
          .then(() => {
            console.log(`Student "${student.name}" verwijderd.`);
            this.loadStudents(group); // Reload students in the group
          })
          .catch((error) => {
            console.error(`Fout bij het verwijderen van student "${student.name}":`, error);
          });
      }
    },
    onGroupDataChanged(items) {
      let _studentGroups = [];
      
      items.forEach((item) => {
        const group = item.data();
        group.id = item.id;
        group.opened = false;
        group.loading = true;
        if(this.$store.state.groupId == group.id){
          console.log("Load students for stored group " + this.$store.state.groupId);
          group.opened = true;
          this.loadStudents(group);
        }
        else{
          group.opened = false;
        }

        _studentGroups.push(group);

        //Move group.opened to top of list
        if(group.opened){
          _studentGroups.unshift(_studentGroups.pop());
        }
      });

      this.studentGroups = _studentGroups;
      this.loadingGroups = false;
    },
    onDragStart(event, student, group) {
      event.dataTransfer.setData('student', JSON.stringify(student));
      event.dataTransfer.setData('sourceGroupId', group.id);
    },
    onDragOver(group) {
      group.isDragOver = true;
    },
    onDragLeave(group) {
      group.isDragOver = false;
    },
    async onDrop(event, targetGroup) {
      targetGroup.isDragOver = false; // Remove highlight on drop
      const student = JSON.parse(event.dataTransfer.getData('student'));
      const sourceGroupId = event.dataTransfer.getData('sourceGroupId');

      if (sourceGroupId === targetGroup.id) {
        console.log('Student is already in this group.');
        return;
      }

      const confirmMove = confirm(`Weet je zeker dat je de leerling "${student.name}" naar de groep "${targetGroup.name}" wilt verplaatsen?`);
      if (!confirmMove) {
        return;
      }

      try {
        // Update the student's groupId in the database
        await db.collection(`/schools/${this.$store.state.schoolId}/students`).doc(student.id).update({
          groupId: targetGroup.id,
        });

        console.log(`Student "${student.name}" verplaatst naar groep "${targetGroup.name}".`);

        // Reload students in both source and target groups
        const sourceGroup = this.studentGroups.find((group) => group.id === sourceGroupId);
        this.loadStudents(sourceGroup);
      } catch (error) {
        console.error(`Fout bij het verplaatsen van student "${student.name}":`, error);
      }
    },
  },
  mounted() {
    this.unsubscribe = db.collection("/schools/" + this.$store.state.schoolId + "/groups").orderBy("name", "desc").onSnapshot(this.onGroupDataChanged);
    // Add a global paste event listener
    window.addEventListener('paste', this.handlePaste);
  },
  beforeUnmount() {
    this.unsubscribe();
    // Remove the global paste event listener
    window.removeEventListener('paste', this.handlePaste);
  },
};
</script>

<style>
 .opened{
    -webkit-box-shadow: inset 3px 3px 6px -1px rgba(0,0,0,0.31);
    -moz-box-shadow: inset 3px 3px 6px -1px rgba(0,0,0,0.31);
    box-shadow: inset 3px 3px 6px -1px rgba(0,0,0,0.31);
    padding: 20px;
 }
.group{
  color: #30b2d9;
}
.modal-content{
  -webkit-box-shadow: 10px 10px 14px -11px rgba(0,0,0,0.34);
  -moz-box-shadow: 10px 10px 14px -11px rgba(0,0,0,0.34);
  box-shadow: 10px 10px 14px -11px rgba(0,0,0,0.34);
}
.student
{
    color: #3c4795;
    padding: 10px;
    font-weight: bold;
    border-radius: 4px;
    -webkit-box-shadow: 3px 3px 2px -1px rgba(0,0,0,0.31);
    -moz-box-shadow: 3px 3px 2px -1px rgba(0,0,0,0.31);
    box-shadow: 3px 3px 2px -1px rgba(0,0,0,0.31);
}
.student a:link{
  text-decoration:none;
}
.drag-over {
  background-color: #e9f7ff;
}

</style>