import "bootswatch/dist/zephyr/bootstrap.css";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import "./custom.css";
import "bootstrap/dist/js/bootstrap.js";

import { createApp } from 'vue';
import { router } from './router';
import { Line } from 'vue-chartjs';
import { Bar } from 'vue-chartjs';

import store from './store';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import defaultAvatar from "@/assets/images/avatar.png";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend
)

// FontAwesome icons
import { library } from '@fortawesome/fontawesome-svg-core'
//import { fas } from '@fortawesome/free-solid-svg-icons' //This is the full set of icons. Using specific icons to reduce bundle size
import { faCircleMinus, faFileImport, faTriangleExclamation, faCheck, faUserCog,faChevronUp, faChevronDown, faUser, faCalendar, faCalendarDays, faBars, faHome, faUsers, faPersonChalkboard,faBarsStaggered,faVrCardboard,faSchool,faGamepad,faGear,faRightFromBracket,faSearch,faHourglass,faClock,faSort,faBullseye,faLock,faPenToSquare,faPlay,faStop,faChild,faCaretLeft,faQuestion,faHashtag,faPersonRunning,faTrophy,faEdit,faBan,faPaperPlane,faRemove,faHourglassHalf,faAngleUp,faAngleDown,faArrowUp,faArrowDown,faCopy,faPaste,faHeart,faStopwatch,faMountainSun,faGraduationCap,faEyeSlash,faUserPlus,faTrash,faTrashRestore,faPlus,faIdCard,faHand,faUpLong,faStar,faGift,faMitten,faRibbon,faCommentDots,faThumbsUp,faCircleInfo} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add( faCircleMinus, faFileImport, faTriangleExclamation, faCheck, faUserCog,faChevronDown, faChevronUp, faUser, faCalendar, faCalendarDays, faBars, faHome, faUsers, faPersonChalkboard,faBarsStaggered,faVrCardboard,faSchool,faGamepad,faGear,faRightFromBracket,faSearch,faHourglass,faClock,faSort,faBullseye,faLock,faPenToSquare,faPlay,faStop,faChild,faCaretLeft,faQuestion,faHashtag,faPersonRunning,faTrophy,faEdit,faBan,faPaperPlane,faRemove,faHourglassHalf,faAngleUp,faAngleDown,faArrowUp,faArrowDown,faCopy,faPaste,faHeart,faStopwatch,faMountainSun,faGraduationCap,faEyeSlash,faUserPlus,faTrash,faTrashRestore,faPlus,faIdCard,faHand,faUpLong,faStar,faGift,faMitten,faRibbon,faCommentDots,faThumbsUp,faCircleInfo);

import App from './App.vue';
import firebase from 'firebase/compat/app';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore;

const storage = firebase.storage();
const db = firebaseApp.firestore();
db.enablePersistence().catch((err) => {
  if (err.code == 'failed-precondition') {
      console.log("enablePersistence only for one tab");
  } 
  else if (err.code == 'unimplemented') {
      console.log("enablePersistence not supported");
  }
});

const auth = firebaseApp.auth();
export { auth, db, firestore,storage, firebase };


//Method to get user data from firestore
async function getUserData() {
   //Update login time for logged user
   let timestamp = firestore.FieldValue.serverTimestamp();
   await db.collection("/users").doc(auth.currentUser.uid).set({
     lastLogin: timestamp,
   }, { merge: true });

  //Get user data to put in store
  try {
    const userRef = db.collection("users").doc(auth.currentUser.uid);
    const doc = await userRef.get();
    store.state.user = doc.data();
    store.state.user.id = auth.currentUser.uid;
    store.state.schoolId = store.state.user.schoolId;

    if(store.state.user.groupIds && store.state.user.groupIds.length > 0){
      store.state.groupId = store.state.user.groupIds[0];
    }
 
    //Default avatar if none is found
    if (!store.state.user.avatar) {
      store.state.user.avatar = defaultAvatar;
    }

    //Check if user is admin
    store.state.user.admin = false;
    const token = await auth.currentUser.getIdTokenResult();
    if (token.claims.admin) {
      console.log("Custom claim admin found");
      store.state.user.admin = true;
    }

    //Check if the boolean user manageStudents field exists. If not, set it to true
    if(store.state.user.manageStudents == null)
    {
        store.state.user.manageStudents = true;
    }
  } 
  catch (error) {
    console.log("Error getting user data: ", error);
  }
}

//Handle user login redirects to login page
auth.onAuthStateChanged(async loggedUser => {
  console.log('Login state change');

  if (loggedUser != null) {  
      console.log(loggedUser.email + ' logged in');
      store.state.loggedUser = loggedUser;
      await Promise.all([getUserData()]);
      
      mountApp();

      // Login via login form
      if (store.state.freshLogin) {
        console.log('Login via email');
        if(store.loginRedirect != null && store.loginRedirect != "" && store.loginRedirect != "/")
        {
          console.log('Redirect after login: ' + store.loginRedirect);
          router.replace(store.loginRedirect);
          store.loginRedirect = "";
          return;
        }
      }
      
      //Window location pathname is emtpy or coming from login page
      console.log("Window location pathname is emtpy or coming from login page : " + window.location.pathname);
      if(window.location.pathname == '/' || window.location.pathname == '/login' || window.location.pathname == ''){
        router.replace('/dashboard');
        return;
      }

      //Default route will be the one we requested
      return;
  } 

  // No user logged in
  console.log("window.location.pathname " + window.location.pathname);
  if(!window.location.pathname.includes('/logindevice') && !window.location.pathname.includes('/invitation')){ 
    if(window.location.pathname != '/login')
      store.loginRedirect = window.location.pathname + window.location.hash;
    
    router.replace('/login');
  } 

  mountApp();
});

function mountApp(){
  //Mount app after we know if we have a user
  if(app != null) return;

  app = createApp(App)
    .component('fa', FontAwesomeIcon)
    .component('line-chart', Line)
    .component('bar-chart', Bar)
    .use(store)
    .use(router);

  //Remove main-loading div
  let mainLoading = document.getElementById('main-loading');
  if(mainLoading != null){
    mainLoading.remove();
  }

  app.mount('#yuha-app');
}

router.beforeEach((to, from) => {
  store.state.showMenu = false;
  console.log(from.path + " -> " + to.path);
  if(to.path != '/login' && to.path.indexOf('/logindevice') < 0 && to.path.indexOf('/invitation') < 0 ){
      if(auth.currentUser == null){
        router.replace('/login');
      }
    }
});

let app;