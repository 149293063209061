<template>
  <div>
    <h6>Mogelijke klant vragen</h6>

    <a class="mb-2 text-decoration-none" @click="addQuestionToArray(this.editingGoal.questions)">+ Nieuwe vraag</a>
    <br><br>
    <div class="input-group smoothContainer" v-for="(question, questionIndex) in this.editingGoal.questions"
      :key="questionIndex">
      <div class="form-floating m-2 mb-2">
        <textarea style="height: 100%;" rows="4" cols="50" v-model="question.question" id="goalInput"
          aria-label="question" class="form-control shared-textarea"
          placeholder="Bijvoorbeeld: 'Een halve pizza graag'">
            </textarea>

        <label v-if="question.type == 3" for="goalInput">Groepsvraag</label>
        <label v-if="question.type != 3" for="goalInput">Vraag</label>
      </div>
      <div class="form-floating m-2 mb-2">
        <div class="form-floating mb-3">
          <input class="form-control" type="number" min="0.0" max="100.0" step="0.01" v-model="question.answer"
            id="new-GoalsAnswer" required>
          <label for="new-GoalsAnswer">Hoeveelheid (0.0 t/m 100)</label>
        </div>

        <div class="form-floating mb-3">
          <input v-if="question.type == 3" class="form-control" type="number" min="1" max="5" step="1"
            v-model="question.amount" id="new-GoalsAmount" required>
          <label v-if="question.type == 3" for="new-GoalsAmount">Klanten (1 t/m 5)</label>
        </div>

        <div class="form-floating mb-2">
          <input type="number" min="0" step="1" v-model="question.timer" class="form-control" placeholder="Lengte"
            id="maxQuestionTimer">
          <label for="maxQuestionTimer">
            <fa icon="fa-solid fa-stopwatch" /> Time-out (sec.)
          </label>
        </div>

        <div class="form-check mt-2">
          <input class="form-check-input" type="radio" v-model="question.type" :id="levelIndex + 'sushi' + questionIndex"
            :name="levelIndex + 'radio' + questionIndex" :value="0" checked>
          <label class="form-check-label" :for="levelIndex + 'sushi' + questionIndex">
            Sushirol
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" v-model="question.type" :id="levelIndex + 'pizza' + questionIndex"
            :name="levelIndex + 'radio' + questionIndex" :value="1">
          <label class="form-check-label" :for="levelIndex + 'pizza' + questionIndex">
            Pizza
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" v-model="question.type" :id="levelIndex + 'juice' + questionIndex"
            :name="levelIndex + 'radio' + questionIndex" :value="2">
          <label class="form-check-label" :for="levelIndex + 'juice' + questionIndex">
            Sap
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" v-model="question.type" :id="levelIndex + 'bread' + questionIndex"
            :name="levelIndex + 'radio' + questionIndex" :value="3">
          <label class="form-check-label" :for="levelIndex + 'bread' + questionIndex">
            Broodjes
          </label>
        </div>
        <div class="form-check mt-2">
          <input class="form-check-input" type="checkbox" :id="levelIndex + 'showHelpers' + questionIndex"
            v-model="question.helpers" checked>
          <label class="form-check-label" :for="levelIndex + 'showHelpers' + questionIndex">
            Toon icoon
          </label>
        </div>
        <div class="form-check mt-2">
          <input class="form-check-input" type="checkbox" :id="levelIndex + 'showAdvancedHelpers' + questionIndex"
            v-model="question.advanced">
          <label class="form-check-label" :for="levelIndex + 'showAdvancedHelpers' + questionIndex">
            Toon visuele hulp
          </label>
        </div>

        <!-- if question.advanced is true show 3 radio buttons with fraction, comma or percentage-->
        <div class="form-check ms-2 mt-2" v-if="question.advanced">
          <div>
            <input class="form-check-input" type="radio" v-model="question.advancedType"
              :id="levelIndex + 'none' + questionIndex" :name="levelIndex + 'radioAdvanced' + questionIndex"
              :value="0">
            <label class="form-check-label" :for="levelIndex + 'none' + questionIndex">
              Automatisch
            </label>
            </div>  
            <div>
            <input class="form-check-input" type="radio" v-model="question.advancedType"
              :id="levelIndex + 'fraction' + questionIndex" :name="levelIndex + 'radioAdvanced' + questionIndex"
              :value="1">
            <label class="form-check-label" :for="levelIndex + 'fraction' + questionIndex">
              Breuk (1/2)
            </label>
            </div>
            <div>
            <input class="form-check-input" type="radio" v-model="question.advancedType"
              :id="levelIndex + 'comma' + questionIndex" :name="levelIndex + 'radioAdvanced' + questionIndex"
              :value="2">
            <label class="form-check-label" :for="levelIndex + 'comma' + questionIndex">
              Komma (0,5)
            </label>
            </div>
            <div>
            <input class="form-check-input" type="radio" v-model="question.advancedType"
              :id="levelIndex + 'percentage' + questionIndex" :name="levelIndex + 'radioAdvanced' + questionIndex"
              :value="3">
            <label class="form-check-label" :for="levelIndex + 'percentage' + questionIndex">
              Percentage (50%)
            </label>
            </div>
        </div>

      </div>
      <div class="input-group mb-3 text-decoration-none">
        <a class="tinyRemove text-danger text-decoration-none"
          @click="removeQuestionFromArray(this.editingGoal.questions, questionIndex)">Vraag verwijderen
          <fa icon="fa-solid fa-circle-minus" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    editingGoal: Object,
    questionTemplate: Object,
    levelIndex: Number
  },
  methods: {
    addQuestionToArray(questions) {
      console.log("Adding new question");
      const newQuestion = { 
        ...this.questionTemplate, 
        timer: 0, 
        advancedType: 0 
      };

      questions.push(
        newQuestion
      );
    },
    removeQuestionFromArray(questions, questionIndex) {
      questions.splice(questionIndex, 1);
    },
    sanitizeQuestionsArray() {
      //Make sure questions have timer and advancedType
      this.editingGoal.questions.forEach(question => {
        if (!question.timer) {
          question.timer = 0;
        }
        if (!question.advancedType) {
          question.advancedType = 0;
        }
      });
    }
  },
  mounted() {
    this.sanitizeQuestionsArray();
  }
}
</script>