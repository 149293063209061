<template>
  <div>
    <h6>Bestellingen meel</h6>
    <a class="mb-2 text-decoration-none" @click="addQuestionToArray(this.editingGoal.questions)">+ Voeg bestelling
      toe</a>
    <br><br>
    <div class="smoothContainer" v-for="(question, questionIndex) in this.editingGoal.questions" :key="questionIndex">
      <div class="input-group">
        <div class="form-floating m-2">
          <textarea v-model="question.questionText" style="height: 100%;" rows="4" cols="50" id="goalInput"
            @input="cleanInputs()" class="form-control shared-textarea" placeholder=""></textarea>
          <label for="goalInput">Bestelling</label>
        </div>

        <div class="form-floating m-2">
          <div class="form-floating mb-3">
            <input class="form-control" type="number" min="0.0" max="10000.0" step="0.01" v-model="question.answer"
              @change="fillQuestion(question)" id="new-GoalsAnswer" required>
            <label for="new-GoalsAnswer">Hoeveel</label>
          </div>

          <div class="form-check mt-2" v-for="unit in units" :key="unit">
            <input class="form-check-input" type="radio" v-model="question.unit" :id="'unit-' + unit" :value="unit">
            <label class="form-check-label" :for="'unit-' + unit">{{ unit }}</label>
          </div>
        </div>
      </div>
      <div class="m-2">
        <!-- Sacks Section -->
        <a class="mb-2 text-decoration-none" @click="addSackToQuestion(question)">+ Voeg zak toe</a>
        <div class="row">
          <div class="smoothContainer col-md-4 m-0" v-for="(sack, sackIndex) in question.sacks" :key="sackIndex">
            <div class="form-floating">
              <input type="number" v-model="sack.mass" class="form-control" placeholder="Mass">
              <label>Hoeveel</label>
            </div>

            <div class="form-check m-2" v-for="unit in units" :key="'sack-' + unit">
              <input class="form-check-input" type="radio" v-model="sack.unit"
                :id="'sackUnit-' + unit + '-' + sackIndex" :value="unit">
              <label class="form-check-label" :for="'sackUnit-' + unit + '-' + sackIndex">{{ unit }}</label>
            </div>
            <div class="mb-5">
              <a class="tinyRemove text-danger text-decoration-none"
                @click="removeSackFromQuestion(question, sackIndex)">Verwijder
                <fa icon="fa-solid fa-circle-minus" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <a class="tinyRemove text-danger text-decoration-none"
          @click="removeQuestionFromArray(this.editingGoal.questions, questionIndex)">Verwijder
          <fa icon="fa-solid fa-circle-minus" />
        </a>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    editingGoal: Object,
    levelIndex: Number
  },
  data() {
    return {
      units: ["kg", "hg", "dag", "g" ] // Define the unit options here
    };
  },
  methods: {
    addQuestionToArray(questions) {
      console.log("Adding new question");
      const newQuestion = {
        questionText: "",
        askedMass: 0,
        unit: "kg",
        yield: 1.0,
        sacks: [
          {
            mass: 1,
            unit: "kg"
          }
        ]
      };
      questions.push(newQuestion);
    },
    fillQuestion(question)
    {
      //Check if question.questionText is empty.
      //If so fill it with the value of question.answer and question.unit
      if(question.questionText === "")
      {
        question.questionText = question.answer + " " + question.unit;
      }
    },
    removeQuestionFromArray(questions, questionIndex) {
      questions.splice(questionIndex, 1);
    },
    addSackToQuestion(question) {
      if (!question.sacks) {
      question.sacks = [];
      }
      const lastSack = question.sacks.length > 0 ? question.sacks[question.sacks.length - 1] : { mass: 1, unit: "kg" };
      const newSack = {
        mass: lastSack.mass,
        unit: lastSack.unit
      };
      question.sacks.push(newSack);
    },
    duplicateSackInQuestion(question, sackIndex) {
      const sackToDuplicate = question.sacks[sackIndex];
      const newSack = {
        mass: sackToDuplicate.mass,
        unit: sackToDuplicate.unit
      };
      question.sacks.push(newSack);
    },
    removeSackFromQuestion(question, sackIndex) {
      question.sacks.splice(sackIndex, 1);
    },
    cleanInputs() {
      // Placeholder for input cleaning logic
    },
    forceToArrayStringMath(stringInput) {
      let cleaned = stringInput.replace(/[\t]+/g, ",");
      cleaned = cleaned.replace(/[*]/g, "x");
      cleaned = cleaned.replace(/[/\\]/g, ":");
      cleaned = cleaned.replace(/[^.,\dx+-:%?=\n]/g, "");
      return cleaned;
    }
  }
};
</script>
